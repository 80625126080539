<template>
  <div>
    <section>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="text-nowrap"
        size="sm"
        @click="handleExportExcel"
      >
        <slot
          name="btn-content"
        />
      </b-button>
    </section>
  </div>
</template>

<script>
import { BButton, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import * as XLSX from 'xlsx-js-style'

export default {
  components: {
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    tableData: {
      type: Array,
      require: true,
      default: () => [],
    },
    tableColumns: {
      type: Array,
      require: true,
      default: () => [],
    },
    tableColumnsEx: {
      type: Array,
      default: () => [],
    },
    tableTitle: {
      type: String,
      default: 'Báo cáo',
    },
    tableSubTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
    }
  },
  methods: {
    handleExportExcel() {
      const defAlignment = {
        wrapText: true,
        vertical: 'center',
        horizontal: 'center',
      }
      const defFill = {
        fgColor: { rgb: 'F2F2F2' },
      }
      const defBorder = { style: 'thin', color: { rgb: '000000' } }
      const defStyleBorder = {
        top: defBorder, bottom: defBorder, left: defBorder, right: defBorder,
      }

      const titleFormat = {
        font: {
          name: 'Arial', sz: 16, color: { rgb: '000000' }, bold: true,
        },
        alignment: defAlignment,
      }
      const subTitleFormat = {
        font: {
          name: 'Arial', sz: 12, color: { rgb: '000000' },
        },
        alignment: defAlignment,
      }
      const headerFormat = {
        font: {
          name: 'Arial', sz: 12, color: { rgb: '000000' }, bold: true,
        },
        alignment: defAlignment,
        border: defStyleBorder,
        fill: defFill,
      }
      const cellTitleFormat = {
        font: {
          name: 'Arial', sz: 12, color: { rgb: '000000' }, bold: true,
        },
        alignment: defAlignment,
        border: defStyleBorder,
      }
      const cellDataFormat = {
        font: {
          name: 'Arial', sz: 12, color: { rgb: '000000' },
        },
        alignment: defAlignment,
        border: defStyleBorder,
      }

      const TOTAL_COL = 1 + this.tableColumns.length
      const TOTAL_ROW = 1 + 1 + 1 + 1 + 1 + this.tableData.length

      // STEP 1: Create a new workbook
      const wb = XLSX.utils.book_new()

      // STEP 2: Create data rows and styles
      const rowsData = []

      // empty, bỏ trống dòng đầu tiên
      rowsData.push([])
      // title
      rowsData.push([
        { v: this.tableTitle, t: 's', s: titleFormat },
      ])
      // sub-title
      rowsData.push([
        { v: this.tableSubTitle, t: 's', s: subTitleFormat },
      ])
      // empty
      rowsData.push([])
      // empty: nếu ko có cột mở rộng
      if (this.tableColumnsEx.length) {
        rowsData.push(this.tableColumnsEx.map(col => ({
          v: col.label || '',
          t: 's',
          s: col.isEmpty ? '' : headerFormat,
        })))
      } else {
        rowsData.push([])
      }
      // header
      rowsData.push(this.tableColumns.filter(col => col.label).map(col => ({
        v: this.breakLineHeader(col.label),
        t: 's',
        s: headerFormat,
      })))

      // data
      // this.tableData.forEach(row => {
      //   const colsData = this.tableColumns.filter(col => col.label)
      //     // eslint-disable-next-line no-restricted-globals
      //     .map(col => (isNaN(row[col.key]) ? (row[col.key] || '0') : this.formatNumber(row[col.key])))
      //   rowsData.push([
      //     ...colsData.map((cell, idx) => ({ v: cell, t: 's', s: idx ? cellDataFormat : cellTitleFormat })),
      //   ])
      // })

      // data
      this.tableData.forEach(row => {
        const colsData = this.tableColumns.filter(col => col.label)
          .map((col, cIdx) => {
            if (cIdx && row.cell4Num && row.cell4Num[col.key]) {
              const cb = row.cell4Num[col.key]
              const data = cb(row)
              const spaceBetween1 = Array.from({ length: 26 - data[0].toString().length - data[1].toString().length }).join(' ')
              const spaceBetween2 = Array.from({ length: 26 - data[2].toString().length - data[3].toString().length }).join(' ')
              return `${data[0]}${spaceBetween1}${data[1]}\n${data[2]}${spaceBetween2}${data[3]}`
            }

            if (cIdx && row.cell2NumVer && row.cell2NumVer[col.key]) {
              const cb = row.cell2NumVer[col.key]
              const data = cb(row)
              return `${data[0]}\n${data[1]}`
            }

            if (cIdx && row.cell2NumHor && row.cell2NumHor[col.key]) {
              const cb = row.cell2NumHor[col.key]
              const data = cb(row)
              const spaceBetween1 = Array.from({ length: 26 - data[0].toString().length - data[1].toString().length }).join(' ')
              return `${data[0]}${spaceBetween1}${data[1]}`
            }

            // eslint-disable-next-line no-restricted-globals
            return (isNaN(row[col.key]) ? (row[col.key] || '0') : this.formatNumber(row[col.key]))
          })
        rowsData.push([
          ...colsData.map((cell, idx) => ({
            v: `${cell}`,
            t: 's',
            s: idx ? cellDataFormat : cellTitleFormat,
            hpt: (row.cell4Num || row.cell3Number) ? 45 : 20,
          })),
        ])
      })

      // STEP 3: Create worksheet with rows; Add worksheet to workbook
      const rowsExcelData = rowsData.map(r => {
        const cols = r || []
        return [
          // bỏ trống lề trái 1 cột
          { v: '', t: 's', s: titleFormat },
          ...cols,
        ]
      })

      const checkFirstColLength = Math.max(...this.tableData.map(row => row[this.tableColumns[0].key].length))

      const ws = XLSX.utils.aoa_to_sheet([...rowsExcelData])
      ws['!cols'] = [{ wch: 10 }, { wch: checkFirstColLength > 18 ? 30 : 20 },
        ...Array.from({ length: TOTAL_COL - 2 }).map(() => ({ wch: 28 }))]
      ws['!rows'] = [{ hpt: 20 }, { hpt: 30 }, { hpt: 20 }, { hpt: 20 }, { hpt: 20 }, { hpt: 45 },
        ...Array.from({ length: TOTAL_ROW - 5 }).map((item, idx) => ({ hpt: rowsExcelData[idx + 6][1].hpt || 20 }))]
      ws['!merges'] = [
        { s: { r: 1, c: 1 }, e: { r: 1, c: TOTAL_COL - 1 } },
        { s: { r: 2, c: 1 }, e: { r: 2, c: TOTAL_COL - 1 } },
      ]
      if (this.tableColumnsEx.length) {
        this.tableColumnsEx.filter(item => item.label).forEach(colEx => {
          ws['!merges'].push(
            { s: { r: 4, c: colEx.start }, e: { r: 4, c: colEx.end } },
          )
        })
      }

      const sheetName = this.tableTitle.length > 30 ? `${this.tableTitle.slice(0, 26)}...` : this.tableTitle
      XLSX.utils.book_append_sheet(wb, ws, sheetName)

      // STEP 4: Write Excel file to browser
      XLSX.writeFile(wb, `${this.tableTitle}.xlsx`)
    },
    breakLineHeader(str) {
      let newStr = str
      if (newStr.length > 20) {
        newStr = str.replace(' ', '__SPACE1__')
          .replace(' ', '__SPACE1__')
          .replace(' ', '\n')
          .replace(' ', '__SPACE2__')
          .replace(' ', '__SPACE2__')
          .replace(' ', '\n')
          .replaceAll('__SPACE1__', ' ')
          .replaceAll('__SPACE2__', ' ')
      } else if (newStr.length > 10) {
        newStr = str.replace(' ', '__SPACE1__')
          .replace(' ', '__SPACE1__')
          .replace(' ', '\n')
          .replaceAll('__SPACE1__', ' ')
      }
      return newStr
    },
  },
}
</script>
