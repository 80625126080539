<template>
  <b-card
    class="custom-border-top-1px"
  >
    <b-card-header class="pl-0 pt-0">
      <b-badge
        variant="light-primary"
        class="font-small-4 px-1 py-50"
      >
        BÁO CÁO PHÒNG KHÁCH HÀNG
      </b-badge>

      <ExportExcel
        :table-title="'BÁO CÁO PHÒNG KHÁCH HÀNG'"
        :table-sub-title="'Thời gian báo cáo: ' + timeFilter"
        :table-data="tableDataFormat"
        :table-columns="tableColumnsReportsPKH"
      >
        <template #btn-content>
          <feather-icon
            class="text-white"
            icon="FileTextIcon"
          />
          Xuất Excel
        </template>
      </ExportExcel>
    </b-card-header>
    <b-table
      ref="refUsersListTableReport"
      class="position-relative table-col-max-width-200 table-col-min-width-100"
      responsive
      bordered
      striped
      primary-key="id"
      show-empty
      small
      empty-text="Không có kết quả nào"
      :items="reportUsers"
      :fields="tableColumnsReportsPKH"
    >
      <template #cell(user_name)="data">
        <span
          class="filterable"
          @click="goContractList({userFilter: data.item.creator_id ? data.item.creator_id.toString() : ''})"
        >{{ data.item.user_name }}</span>
      </template>

      <template #cell(count_total)="data">
        <div
          class="d-flex flex-column align-content-center"
          @click="goContractList({userFilter: data.item.creator_id ? data.item.creator_id.toString() : ''})"
        >
          <div class="d-flex justify-content-between align-items-center">
            <span
              class="filterable font-weight-bolder text-center"
            >{{ formatNumber(data.item.count_total || 0) }}</span>
            <span class="font-small-3 text-danger text-right">~{{ (100 * (data.item.count_total / sumRow.count_total || 0)).toFixed(0) }}%</span>
          </div>
        </div>
      </template>

      <template #cell(sum_origin)="data">
        <div
          class="d-flex flex-column align-content-center"
          @click="goContractList({userFilter: data.item.creator_id ? data.item.creator_id.toString() : ''})"
        >
          <div class="d-flex justify-content-between align-items-center">
            <span
              class="filterable font-weight-bolder text-center"
            >{{ formatNumber(data.item.sum_origin || 0) }}</span>
            <span class="font-small-3 text-danger text-right">~{{ (100 * (data.item.sum_origin / sumRow.sum_origin || 0)).toFixed(0) }}%</span>
          </div>
        </div>
      </template>

      <template #cell(contract_total)="data">
        <div
          class="d-flex flex-column align-content-center"
          @click="goContractList({userFilter: data.item.creator_id ? data.item.creator_id.toString() : '', statusFilter: $status.list.filter(item => item.type === 'hd').map(item => item.value)})"
        >
          <div class="d-flex justify-content-between align-items-center">
            <span
              class="filterable font-weight-bolder text-center"
            >{{ formatNumber(data.item.contract_total || 0) }}</span>
            <span class="font-small-3 text-danger text-right">~{{ (100 * (data.item.contract_total / sumRow.contract_total || 0)).toFixed(0) }}%</span>
          </div>
        </div>
      </template>

      <template #cell(hd_sum_origin)="data">
        <div
          class="d-flex flex-column align-content-center"
          @click="goContractList({userFilter: data.item.creator_id ? data.item.creator_id.toString() : '', statusFilter: $status.list.filter(item => item.type === 'hd').map(item => item.value)})"
        >
          <div class="d-flex justify-content-between align-items-center">
            <span
              class="filterable font-weight-bolder text-center"
            >{{ formatNumber(data.item.hd_sum_origin || 0) }}</span>
            <span class="font-small-3 text-danger text-right">~{{ (100 * (data.item.hd_sum_origin / sumRow.hd_sum_origin || 0)).toFixed(0) }}%</span>
          </div>
        </div>
      </template>

      <template #cell(debt_count)="data">
        <div
          class="d-flex flex-column align-content-center"
          @click="goContractList({typeDateFilter: 'from_collect_at', userFilter: data.item.creator_id ? data.item.creator_id.toString() : '', statusFilter: $status.list.filter(item => item.type === 'hd').map(item => item.value), hasCollectedFilter: 'true'})"
        >
          <div class="d-flex justify-content-between align-items-center">
            <span
              class="filterable font-weight-bolder text-center"
            >{{ formatNumber(data.item.debt_count || 0) }}</span>
            <span class="font-small-3 text-danger text-right">~{{ (100 * (data.item.debt_count / sumRow.debt_count || 0)).toFixed(0) }}%</span>
          </div>
        </div>
      </template>

      <template #cell(debt_total)="data">
        <div
          class="d-flex flex-column align-content-center"
          @click="goContractList({typeDateFilter: 'from_collect_at', userFilter: data.item.creator_id ? data.item.creator_id.toString() : '', statusFilter: $status.list.filter(item => item.type === 'hd').map(item => item.value), hasCollectedFilter: 'true'})"
        >
          <div class="d-flex justify-content-between align-items-center">
            <span
              class="filterable font-weight-bolder text-center"
            >{{ formatNumber(data.item.debt_total || 0) }}</span>
            <span class="font-small-3 text-danger text-right">~{{ (100 * (data.item.debt_total / sumRow.debt_total || 0)).toFixed(0) }}%</span>
          </div>
        </div>
      </template>

      <template #cell(debt_buy_original_total)="data">
        <div
          class="d-flex flex-column align-content-center"
          @click="goContractList({typeDateFilter: 'from_collect_at', userFilter: data.item.creator_id ? data.item.creator_id.toString() : '', statusFilter: $status.list.filter(item => item.type === 'hd').map(item => item.value), hasCollectedFilter: 'true'})"
        >
          <div class="d-flex justify-content-between align-items-center">
            <span
              class="filterable font-weight-bolder text-center"
            >{{ formatNumber(data.item.debt_buy_original_total || 0) }}</span>
            <span class="font-small-3 text-danger text-right">~{{ (100 * (data.item.debt_buy_original_total / sumRow.debt_buy_original_total || 0)).toFixed(0) }}%</span>
          </div>
        </div>
      </template>

      <!-- Column: chi tiết -->
      <template #cell(actions)="data">
        <div class="text-right">
          <b-button
            size="sm"
            variant="primary"
            @click="$emit('go-detail', data.item)"
          >Chi tiết
          </b-button>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BTable, VBTooltip, BBadge, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ExportExcel from '@/views/components/export-excel/ExportExcel.vue'

export default {
  components: {
    ExportExcel,
    BCardHeader,
    BCard,
    BTable,
    BBadge,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    reportUsers: {
      type: Array,
      default: () => [],
    },
    timeFilter: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      rangeDateFilter: '',
      tableColumnsReportsPKH: [
        {
          label: 'Tên nhân viên',
          key: 'user_name',
          tdClass: 'pt-50 pb-50',
        },
        {
          label: 'Số HS',
          key: 'count_total',
        },
        {
          label: 'Tổng giá trị HS',
          key: 'sum_origin',
        },
        {
          label: 'Số HĐ',
          key: 'contract_total',
        },
        {
          label: 'Tổng giá trị HĐ',
          key: 'hd_sum_origin',
        },
        {
          label: 'Số HĐ đòi được',
          key: 'debt_count',
        },
        {
          label: 'Tổng giá trị HĐ đòi được',
          key: 'debt_total',
        },
        {
          label: 'Doanh thu tổng công ty',
          key: 'debt_buy_original_total',
        },
        {
          label: '',
          key: 'actions',
        },
      ],
    }
  },
  computed: {
    tableDataFormat() {
      return this.reportUsers.map(item => ({
        ...item,
        // cell2NumVer: undefined,
        cell2NumHor: {
          count_total: obj => [
            this.formatNumber(obj.count_total || 0),
            `~${(100 * (obj.count_total / this.sumRow.count_total || 0)).toFixed(0)}%`,
          ],
          sum_origin: obj => [
            this.formatNumber(obj.sum_origin || 0),
            `~${(100 * (obj.sum_origin / this.sumRow.sum_origin || 0)).toFixed(0)}%`,
          ],
          contract_total: obj => [
            this.formatNumber(obj.contract_total || 0),
            `~${(100 * (obj.contract_total / this.sumRow.contract_total || 0)).toFixed(0)}%`,
          ],
          hd_sum_origin: obj => [
            this.formatNumber(obj.hd_sum_origin || 0),
            `~${(100 * (obj.hd_sum_origin / this.sumRow.hd_sum_origin || 0)).toFixed(0)}%`,
          ],
          debt_count: obj => [
            this.formatNumber(obj.debt_count || 0),
            `~${(100 * (obj.debt_count / this.sumRow.debt_count || 0)).toFixed(0)}%`,
          ],
          debt_total: obj => [
            this.formatNumber(obj.debt_total || 0),
            `~${(100 * (obj.debt_total / this.sumRow.debt_total || 0)).toFixed(0)}%`,
          ],
          debt_buy_original_total: obj => [
            this.formatNumber(obj.debt_buy_original_total || 0),
            `~${(100 * (obj.debt_buy_original_total / this.sumRow.debt_buy_original_total || 0)).toFixed(0)}%`,
          ],
        },
        cell4Num: undefined,
      }))
    },
    sumRow() {
      return this.reportUsers[this.reportUsers.length - 1]
    },
  },
  methods: {
    goContractList(filter, withTimeFilter = true) {
      this.$router.push({
        name: 'contracts-list',
        query: {
          filter: JSON.stringify({
            rangeDateFilter: withTimeFilter ? this.updateRangeFilter() : '',
            ...filter,
          }),
        },
      })
    },
    updateRangeFilter() {
      const q = this.$route.query
      const m = q.monthFilter || ''
      const y = q.yearFilter || ''
      if (!y) {
        this.rangeDateFilter = ' to '
      } else if (!m) {
        this.rangeDateFilter = `${y}-01-01 to ${y}-12-31`
      } else {
        const endDate = new Date(y, m, 0).getDate()
        this.rangeDateFilter = `${y}-${m}-01 to ${y}-${m}-${endDate}`
      }
      return this.rangeDateFilter
    },
    goReportTeamDetail(team) {
      if (!team.department_id) return
      const q = this.$route.query
      const monthFilter = q.monthFilter || ''
      const yearFilter = q.yearFilter || ''
      this.$router.push({
        name: 'reports-team',
        params: { id: team.department_id },
        query: {
          name: team.department_name, monthFilter, yearFilter, t: new Date().getTime(),
        },
      })
    },
  },
}
</script>
