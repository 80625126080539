<template>
  <div>
    <b-card class="custom-border-top-1px">
      <b-card-header class="pl-0 pt-0">
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          HỢP ĐỒNG MỞ & BÁO CÁO THANH LÝ
        </b-badge>

        <ExportExcel
          :table-title="'BÁO CÁO HỢP ĐỒNG MỞ & BÁO CÁO THANH LÝ'"
          :table-sub-title="'Thời gian báo cáo: ' + timeFilter"
          :table-data="tableData"
          :table-columns="tableColumns"
          :table-columns-ex="tableColumnsEx"
        >
          <template #btn-content>
            <feather-icon
              class="text-white"
              icon="FileTextIcon"
            />
            Xuất Excel
          </template>
        </ExportExcel>
      </b-card-header>
      <b-table
        class="position-relative text-center table-col-max-width-100"
        responsive
        bordered
        small
        striped
        primary-key="id"
        show-empty
        empty-text="Không có kết quả nào"
        :items="tableData"
        :fields="tableColumns"
      >
        <template #thead-top>
          <b-tr>
            <b-th
              colspan="3"
            />
            <b-th
              colspan="2"
              class="text-primary"
            >
              HĐ ĐÃ THANH LÝ {{ timeFilter }}
            </b-th>
            <b-th
              colspan="2"
              class="text-primary"
            >
              TỔNG HỢP ĐỒNG ĐÃ THANH LÝ
            </b-th>
          </b-tr>
        </template>

        <template
          #head(department_name)
          rowspan="2"
        />

        <template #cell(department_name)="data">
          <span
            class="cursor-pointer"
            @click="goReportTeamDetail(data.item)"
          >{{ data.item.department_name }}</span>
        </template>

        <template #cell(contract_open)="data">
          <span
            class="filterable"
            @click="goContractList({departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', statusFilter: 'contract_open'}, false)"
          >{{ data.item.contract_open || 0 }}</span>
        </template>

        <template #cell(contract_liquid)="data">
          <span
            class="filterable"
            @click="goContractList({departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', statusFilter: 'liquidity_processing'}, false)"
          >{{ data.item.contract_liquid || 0 }}</span>
        </template>

        <template #cell(count_is_collection)="data">
          <span
            class="filterable"
            @click="goContractList({departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', hasCollectedFilter: 'true', statusFilter: 'liquidity_completed'})"
          >{{ data.item.count_is_collection || 0 }}</span>
        </template>

        <template #cell(count_not_collection)="data">
          <span
            class="filterable"
            @click="goContractList({departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', hasCollectedFilter: 'false', statusFilter: 'liquidity_completed'})"
          >{{ data.item.count_not_collection || 0 }}</span>
        </template>

        <template #cell(is_collection)="data">
          <span
            class="filterable"
            @click="goContractList({departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', hasCollectedFilter: 'true',statusFilter: 'liquidity_completed'}, false)"
          >{{ data.item.is_collection || 0 }}</span>
        </template>

        <template #cell(not_collection)="data">
          <span
            class="filterable"
            @click="goContractList({departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', hasCollectedFilter: 'false',statusFilter: 'liquidity_completed'}, false)"
          >{{ data.item.not_collection || 0 }}</span>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge, BCard, BCardHeader, BTable, BTh, BTr, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ExportExcel from '@/views/components/export-excel/ExportExcel.vue'

export default {
  components: {
    ExportExcel,
    BCardHeader,
    BCard,
    BTable,
    BBadge,
    BTr,
    BTh,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    refs: {
      type: Array,
      default: () => [],
    },
    timeFilter: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      rangeDateFilter: '',
      tableColumns: [
        {
          label: 'ĐPC',
          key: 'department_name',
          tdClass: 'text-nowrap text-uppercase text-primary pt-50 pb-50',
          thClass: 'text-primary',
          stickyColumn: true,
        },
        {
          label: 'HĐ MỞ',
          key: 'contract_open',
          tdClass: 'text-nowrap text-uppercase text-primary pt-50 pb-50',
        },
        {
          label: 'TỔNG CHỜ TL',
          key: 'contract_liquid',
          tdClass: 'text-nowrap text-uppercase text-primary pt-50 pb-50',
        },
        {
          label: 'ĐÃ ĐÒI ĐƯỢC',
          key: 'count_is_collection',
        },
        {
          label: 'KHÔNG ĐÒI ĐƯỢC',
          key: 'count_not_collection',
        },
        {
          label: 'ĐÃ ĐÒI ĐƯỢC',
          key: 'is_collection',
          formatter: (val, key, item) => item.is_collection || 0,
        },
        {
          label: 'KHÔNG ĐÒI ĐƯỢC',
          key: 'not_collection',
          formatter: (val, key, item) => item.not_collection || 0,
        },
      ],
      tableColumnsEx: [
        { isEmpty: true },
        { isEmpty: true },
        { isEmpty: true },
        {
          label: `HĐ ĐÃ THANH LÝ ${(this.timeFilter.toUpperCase())}`,
          key: 'col_ex_1',
          start: 4,
          end: 5,
        },
        {},
        {
          label: 'TỔNG HỢP ĐỒNG ĐÃ THANH LÝ',
          key: 'col_ex_2',
          start: 6,
          end: 7,
        },
        {},
      ],
    }
  },
  methods: {
    goContractList(filter, withTimeFilter = true) {
      this.$router.push({
        name: 'contracts-list',
        query: {
          filter: JSON.stringify({
            rangeDateFilter: withTimeFilter ? this.updateRangeFilter() : '',
            ...filter,
          }),
        },
      })
    },
    updateRangeFilter() {
      const q = this.$route.query
      const m = q.monthFilter || ''
      const y = q.yearFilter || ''
      if (!y) {
        this.rangeDateFilter = ' to '
      } else if (!m) {
        this.rangeDateFilter = `${y}-01-01 to ${y}-12-31`
      } else {
        const endDate = new Date(y, m, 0).getDate()
        this.rangeDateFilter = `${y}-${m}-01 to ${y}-${m}-${endDate}`
      }
      return this.rangeDateFilter
    },
    goReportTeamDetail(team) {
      if (!team.department_id) return
      const q = this.$route.query
      const monthFilter = q.monthFilter || ''
      const yearFilter = q.yearFilter || ''
      this.$router.push({
        name: 'reports-team',
        params: { id: team.department_id },
        query: {
          name: team.department_name, monthFilter, yearFilter, t: new Date().getTime(),
        },
      })
    },
  },
}
</script>
