<template>
  <div>
    <b-card class="custom-border-top-1px">
      <b-card-header class="pl-0 pt-0">
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          BÁO CÁO THU NỢ
        </b-badge>

        <ExportExcel
          :table-title="'BÁO CÁO THU NỢ'"
          :table-sub-title="'Thời gian báo cáo: ' + timeFilter"
          :table-data="tableDataFormat"
          :table-columns="tableColumnsReportsTeam"
        >
          <template #btn-content>
            <feather-icon
              class="text-white"
              icon="FileTextIcon"
            />
            Xuất Excel
          </template>
        </ExportExcel>
      </b-card-header>
      <b-table
        ref="refUsersListTable"
        class="position-relative"
        responsive
        bordered
        striped
        small
        primary-key="id"
        show-empty
        empty-text="Không có kết quả nào"
        :items="tableDataFormat"
        :fields="tableColumnsReportsTeam"
      >
        <template #cell(department_name)="data">
          <span
            class="cursor-pointer"
            @click="goReportTeamDetail(data.item)"
          >{{ data.item.department_name }}</span>
        </template>

        <template #cell(debt_count)="data">
          <div class="d-flex justify-content-center">
            <span
              class="filterable text-center"
              @click="goContractList({typeDateFilter: 'from_collect_at', departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', hasCollectedFilter: 'true'})"
            >{{ data.item.debt_count || 0 }}</span>
          </div>
        </template>

        <template #cell(total)="data">
          <div
            class="d-flex flex-column align-content-center"
            @click="openModalDebtDetail(data.item)"
          >
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="filterable font-weight-bolder text-center"
              >{{ formatNumber(data.item.total || 0) }}</span>
              <span
                class="font-small-3 text-danger text-right"
              >~{{ (100 * (data.item.total / sumRow.total || 0)).toFixed(0) }}%</span>
            </div>
          </div>
        </template>

        <template #cell(total_amount_for_team)="data">
          <div
            class="d-flex flex-column align-content-center"
            @click="openModalDebtDetail(data.item)"
          >
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="filterable font-weight-bolder text-center"
              >{{ formatNumber(data.item.total_amount_for_team || 0) }}</span>
              <span
                class="font-small-3 text-danger text-right"
              >~{{ (100 * (data.item.total_amount_for_team / sumRow.total_amount_for_team || 0)).toFixed(0) }}%</span>
            </div>
          </div>
        </template>

        <template #cell(total_amount_for_song_long)="data">
          <div
            class="d-flex flex-column align-content-center"
            @click="openModalDebtDetail(data.item)"
          >
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="filterable font-weight-bolder text-center"
              >{{ formatNumber(data.item.total_amount_for_song_long || 0) }}</span>
              <span
                class="font-small-3 text-danger text-right"
              >~{{
                (100 * (data.item.total_amount_for_song_long / (sumRow.total_amount_for_song_long || ((sumRow.total_amount_buy_original || 0) - (sumRow.total_amount_for_team || 0))) || 0)).toFixed(0)
              }}%</span>
            </div>
          </div>
        </template>

        <template #cell(amountTeamLeader)="data">
          <div class="d-flex justify-content-center">
            <span
              class="filterable text-center"
              @click="goReportTeamDetail(data.item)"
            >{{ formatNumber(data.item.amountTeamLeader) }}</span>
          </div>
        </template>

        <template #cell(amountTeamMember)="data">
          <div class="d-flex justify-content-center">
            <span
              class="filterable text-center"
              @click="goReportTeamDetail(data.item)"
            >{{ formatNumber(getSalaryTeam(data.item.sum_transactions).amountTeamMember) }}</span>
          </div>
        </template>

        <!-- Column: chi tiết -->
        <template #cell(actions)="data">
          <div class="text-right">
            <b-button
              size="sm"
              variant="primary"
              @click="$emit('go-detail', data.item)"
            >Chi tiết
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge, BButton, BCard, BCardHeader, BCardText, BCol, BTable, VBTooltip, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import reportStoreModule from '@/views/reports/reportStoreModule'
import useReportsOverview from '@/views/reports/overview/useReportsOverview'
import ExportExcel from '@/views/components/export-excel/ExportExcel.vue'

export default {
  components: {
    BCol,
    BCardText,
    ExportExcel,
    BCardHeader,
    BCard,
    BTable,
    BBadge,
    BButton,
    BRow,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    reportTeams: {
      type: Array,
      default: () => [],
    },
    timeFilter: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      rangeDateFilter: '',
      tableColumnsReportsTeam: [
        {
          label: 'Tên đội',
          key: 'department_name',
          tdClass: 'text-nowrap text-uppercase text-primary pt-50 pb-50',
        },
        {
          label: 'Số HĐ thu được',
          key: 'debt_count',
        },
        {
          label: 'Tổng thu',
          key: 'total',
        },
        {
          label: 'Doanh thu đội',
          key: 'total_amount_for_team',
        },
        {
          label: 'Doanh thu công ty',
          key: 'total_amount_for_song_long',
          tdClass: 'text-nowrap text-uppercase text-primary pt-50 pb-50',
        },
        {
          label: 'Lương đội trưởng',
          key: 'amountTeamLeader',
        },
        {
          label: 'Lương nhân viên',
          key: 'amountTeamMember',
        },
        {
          label: '',
          key: 'actions',
        },
      ],
    }
  },
  computed: {
    tableDataFormat() {
      return this.reportTeams.map(item => ({
        ...item,
        total_amount_for_song_long: item.total_amount_for_song_long
            || ((item.total_amount_buy_original || 0) - (item.total_amount_for_team || 0)),
        amountTeamLeader: this.getSalaryTeam(item.sum_transactions).amountTeamLeader,
        amountTeamMember: this.getSalaryTeam(item.sum_transactions).amountTeamMember,
        // cell2NumVer: undefined,
        cell2NumHor: {
          total: obj => [
            this.formatNumber(obj.total || 0),
            `~${(100 * (obj.total / this.sumRow.total || 0)).toFixed(0)}%`,
          ],
          total_amount_for_team: obj => [
            this.formatNumber(obj.total_amount_for_team || 0),
            `~${(100 * (obj.total_amount_for_team / this.sumRow.total_amount_for_team || 0)).toFixed(0)}%`,
          ],
          total_amount_for_song_long: obj => [
            this.formatNumber(obj.total_amount_for_song_long || 0),
            `~${(100 * (obj.total_amount_for_song_long
                / (this.sumRow.total_amount_for_song_long
                    || ((this.sumRow.total_amount_buy_original || 0) - (this.sumRow.total_amount_for_team || 0)))
                || 0)).toFixed(0)}%`,
          ],
        },
        cell4Num: undefined,
      }))
    },
    sumRow() {
      return this.reportTeams[this.reportTeams.length - 1]
    },
  },
  methods: {
    openModalDebtDetail(data) {
      this.$emit('open-modal-debt-detail', data)
    },
    goContractList(filter, withTimeFilter = true) {
      this.$router.push({
        name: 'contracts-list',
        query: {
          filter: JSON.stringify({
            rangeDateFilter: withTimeFilter ? this.updateRangeFilter() : '',
            ...filter,
          }),
        },
      })
    },
    goReportTeamDetail(team) {
      if (!team.department_id) return
      const q = this.$route.query
      const monthFilter = q.monthFilter || ''
      const yearFilter = q.yearFilter || ''
      this.$router.push({
        name: 'reports-team',
        params: { id: team.department_id },
        query: {
          name: team.department_name,
          monthFilter,
          yearFilter,
          t: new Date().getTime(),
        },
      })
    },
    updateRangeFilter() {
      const q = this.$route.query
      const m = q.monthFilter || ''
      const y = q.yearFilter || ''
      if (!y) {
        this.rangeDateFilter = ' to '
      } else if (!m) {
        this.rangeDateFilter = `${y}-01-01 to ${y}-12-31`
      } else {
        const endDate = new Date(y, m, 0).getDate()
        this.rangeDateFilter = `${y}-${m}-01 to ${y}-${m}-${endDate}`
      }
      return this.rangeDateFilter
    },
    goReportTeam(d) {
      this.$router.push({
        name: 'reports-team',
        params: { id: d.department_id },
        query: { name: d.department_name },
      })
    },
  },
  setup() {
    const REPORT_STORE_MODULE_NAME = 'report'
    if (!store.hasModule(REPORT_STORE_MODULE_NAME)) store.registerModule(REPORT_STORE_MODULE_NAME, reportStoreModule)
    onUnmounted(() => {
      if (store.hasModule(REPORT_STORE_MODULE_NAME)) store.unregisterModule(REPORT_STORE_MODULE_NAME)
    })

    const reportTeamListMember = ref([])

    return {
      reportTeamListMember,
      ...useReportsOverview(),
    }
  },
}
</script>
