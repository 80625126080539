<template>
  <div>
    <div
      class="d-flex justify-content-between cursor-pointer user-select-none"
      @click="isShowContent = !isShowContent"
    >
      <b-badge
        variant="light-primary"
        class="font-small-4 px-1 py-50"
      >
        NGUỒN
      </b-badge>
      <feather-icon
        :icon="isShowContent ? 'ChevronUpIcon' : 'ChevronDownIcon'"
        size="18"
        class="cursor-pointer"
      />
    </div>
    <div v-show="isShowContent">
      <br>
      <span
        v-for="(ref, idx) in refs"
        :key="idx"
        class="ref-item"
        :class="getClassByCount(ref.count)"
      >
        <span>
          {{ ref.info_how_to_know_me }}
        </span>:
        <span>{{ ref.count }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import { BBadge, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    refs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isShowContent: true,
    }
  },
  methods: {
    getClassByCount(count) {
      const c = parseFloat(count)
      if (c >= 50) return 'font-weight-bolder text-danger'
      if (c >= 30) return 'font-weight-bolder text-success'
      if (c >= 10) return 'font-weight-bolder text-primary'
      if (c >= 5) return 'font-weight-bolder'
      return ''
    },
  },
}
</script>
<style lang="scss">
.ref-item {
  display: inline-block;
  margin-bottom: .5rem;
  margin-right: .5rem;
  padding: .25rem .5rem;
  border: 1px solid gainsboro;
  border-radius: .25rem;
}
</style>
