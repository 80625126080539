<template>
  <div>
    <b-card class="custom-border-top-1px">
      <b-card-header class="pl-0 pt-0 pr-0">
        <b-badge
          variant="light-success"
          class="font-small-4 px-1 py-50"
        >
          BÁO CÁO HỒ SƠ
        </b-badge>

        <ExportExcel
          :table-title="'BÁO CÁO HỒ SƠ'"
          :table-sub-title="'Thời gian báo cáo: ' + timeFilter"
          :table-data="tableDataFormat"
          :table-columns="tableColumns"
        >
          <template #btn-content>
            <feather-icon
              class="text-white"
              icon="FileTextIcon"
            />
            Xuất Excel
          </template>
        </ExportExcel>

      </b-card-header>
      <b-table
        class="position-relative text-center table-col-max-width-100 table-col-min-width-100"
        responsive
        bordered
        small
        striped
        primary-key="id"
        show-empty
        empty-text="Không có kết quả nào"
        :items="tableDataFormat"
        :fields="tableColumns"
      >
        <template #cell(department_name)="data">
          <span
            class="cursor-pointer"
            @click="goReportTeamDetail(data.item)"
          >{{ data.item.department_name }}</span>
        </template>

        <template #cell(contract_count)="data">
          <div
            class="d-flex flex-column align-content-center"
            @click="goContractList({isAssignedFilter: 'true', departmentFilter: data.item.department_id ? data.item.department_id.toString() : ''})"
          >
            <div class="d-flex justify-content-center align-items-end">
              <span class="filterable font-weight-bolder text-center">{{ data.item.contract_count || 0 }}</span>
              <!--              <span class="font-small-3 text-danger text-right">{{ data.item.topRightData }}</span>-->
            </div>
            <span class="warning text-nowrap font-small-3 text-center">{{ formatNumber(data.item.total_sum) }}</span>
          </div>
        </template>

        <template #cell(company)="data">
          <div
            class="d-flex flex-column align-content-center"
            @click="goContractList({isAssignedFilter: 'true', departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', categoryFilter: 'company'})"
          >
            <div class="d-flex justify-content-between align-items-end">
              <span
                class="filterable font-weight-bolder text-left"
              >{{ data.item.company || 0 }}</span>
              <span class="font-small-3 text-danger text-right">~{{ (100 * (data.item.company / sumRow.company || 0)).toFixed(0) }}%</span>
            </div>
            <div class="d-flex justify-content-between align-items-end">
              <span class="warning text-nowrap font-small-3 text-right">{{ formatNumber(data.item.sum_company) }}</span>
              &nbsp;
              <span class="warning text-nowrap font-small-3 text-right">~{{ (100 * data.item.sum_company / (data.item.sum_company + data.item.sum_personal) || 0).toFixed(0) }}%</span>
            </div>
          </div>
        </template>

        <template #cell(person)="data">
          <div
            class="d-flex flex-column align-content-center"
            @click="goContractList({isAssignedFilter: 'true', departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', categoryFilter: 'personal'})"
          >
            <div class="d-flex justify-content-between align-items-end">
              <span
                class="filterable font-weight-bolder text-left"
              >{{ data.item.personal || 0 }}</span>
              <span class="font-small-3 text-danger text-right">~{{ (100 * (data.item.personal / sumRow.personal || 0)).toFixed(0) }}%</span>
            </div>
            <div class="d-flex justify-content-between align-items-end">
              <span class="warning text-nowrap font-small-3 text-right">{{ formatNumber(data.item.sum_personal) }}</span>
              &nbsp;
              <span class="warning text-nowrap font-small-3 text-right">~{{ (100 * data.item.sum_personal / (data.item.sum_company + data.item.sum_personal) || 0).toFixed(0) }}%</span>
            </div>
          </div>
        </template>

        <template #cell(offer_to_sign)="data">
          <div
            class="d-flex flex-column align-content-center"
            @click="goContractList({isAssignedFilter: 'true', departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', statusFilter: 'offer_to_sign'})"
          >
            <div class="d-flex justify-content-center align-items-center">
              <span
                class="filterable font-weight-bolder text-center"
              >{{ data.item.offer_to_sign || 0 }}</span>
              <!--              <span class="font-small-3 text-danger text-right">{{ (100 * (data.item.offer_to_sign / sumRow.offer_to_sign || 0)).toFixed(0) }}%</span>-->
            </div>
            <!--            <span class="warning text-nowrap font-small-3 text-right">{{ formatNumber(data.item.total_sum_offer_to_sign) }}đ</span>-->
          </div>
        </template>

        <template #cell(contract_signed)="data">
          <div
            class="d-flex flex-column align-content-center"
            @click="goContractList({typeDateFilter: 'default', isAssignedFilter: 'true', departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', statusFilter: $status.list.filter(item => item.type === 'hd').map(item => item.value)})"
          >
            <div class="d-flex justify-content-center align-items-center">
              <span
                class="filterable font-weight-bolder text-center"
              >{{
                formatNumber(parseFloat(data.item.contract_signed || 0)
                  + parseFloat(data.item.contract_open || 0)
                  + parseFloat(data.item.liquidity_processing || 0)
                  + parseFloat(data.item.liquidity_completed || 0),
                )
              }}</span>
              <!--              <span class="font-small-3 text-danger text-right">{{ (100 * ((parseFloat(data.item.contract_signed || 0)-->
              <!--                + parseFloat(data.item.contract_open || 0)-->
              <!--                + parseFloat(data.item.liquidity_processing || 0)-->
              <!--                + parseFloat(data.item.liquidity_completed || 0)) / (-->
              <!--                parseFloat(sumRow.contract_signed || 0)-->
              <!--                + parseFloat(sumRow.contract_open || 0)-->
              <!--                + parseFloat(sumRow.liquidity_processing || 0)-->
              <!--                + parseFloat(sumRow.liquidity_completed || 0)-->
              <!--              ) || 0)).toFixed(0) }}%</span>-->
            </div>
            <!--            <span class="warning text-nowrap font-small-3 text-right">{{ formatNumber(parseFloat(data.item.total_sum_contract_signed || 0)-->
            <!--              + parseFloat(data.item.total_sum_contract_open || 0)-->
            <!--              + parseFloat(data.item.total_sum_liquidity_processing || 0)-->
            <!--              + parseFloat(data.item.total_sum_liquidity_completed || 0),-->
            <!--            ) }}đ</span>-->
          </div>
        </template>

        <template #cell(contract_denied)="data">
          <div
            class="d-flex flex-column align-content-center"
            @click="goContractList({isAssignedFilter: 'true', departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', statusFilter: 'contract_denied'})"
          >
            <div class="d-flex justify-content-center align-items-center">
              <span
                class="filterable font-weight-bolder text-center"
              >{{ data.item.contract_denied || 0 }}</span>
              <!--              <span class="font-small-3 text-danger text-right">{{ (100 * (data.item.contract_denied / sumRow.contract_denied || 0)).toFixed(0) }}%</span>-->
            </div>
            <!--            <span class="warning text-nowrap font-small-3 text-right">{{ formatNumber(data.item.total_sum_contract_denied) }}đ</span>-->
          </div>
        </template>

        <template #cell(verify_deny)="data">
          <div
            class="d-flex flex-column align-content-center"
            @click="goContractList({isAssignedFilter: 'true', departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', statusFilter: 'verify_deny'})"
          >
            <div class="d-flex justify-content-center align-items-center">
              <span
                class="filterable font-weight-bolder text-center"
              >{{ data.item.verify_deny || 0 }}</span>
              <!--              <span class="font-small-3 text-danger text-right">{{ (100 * (data.item.verify_deny / sumRow.verify_deny || 0)).toFixed(0) }}%</span>-->
            </div>
            <!--            <span class="warning text-nowrap font-small-3 text-right">{{ formatNumber(data.item.total_sum_verify_deny) }}đ</span>-->
          </div>
        </template>

        <template #cell(verify_new)="data">
          <div
            class="d-flex flex-column align-content-center"
            @click="goContractList({isAssignedFilter: 'true', departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', statusFilter: 'verify_new'})"
          >
            <div class="d-flex justify-content-center align-items-center">
              <span
                class="filterable font-weight-bolder text-center"
              >{{ (data.item.verify_new || 0) + (data.item.verify_approving || 0) }}</span>
              <!--              <span class="font-small-3 text-danger text-right">{{ (100 * (data.item.verify_new / sumRow.verify_new || 0)).toFixed(0) }}%</span>-->
            </div>
            <!--            <span class="warning text-nowrap font-small-3 text-right">{{ formatNumber(data.item.total_sum_verify_new) }}đ</span>-->
          </div>
        </template>

        <template #cell(total_offer_to_sign)="data">
          <div
            class="d-flex flex-column align-content-center"
            @click="goContractList({isAssignedFilter: 'true', departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', statusFilter: 'offer_to_sign'}, false)"
          >
            <div class="d-flex justify-content-center align-items-center">
              <span
                class="filterable font-weight-bolder text-center"
              >{{ data.item.total_offer_to_sign || 0 }}</span>
              <!--              <span class="font-small-3 text-danger text-right">{{ (100 * (data.item.total_offer_to_sign / sumRow.total_offer_to_sign || 0)).toFixed(0) }}%</span>-->
            </div>
            <!--            <span class="warning text-nowrap font-small-3 text-right">{{ formatNumber(data.item.total_sum_all_offer_to_sign) }}đ</span>-->
          </div>
        </template>
      </b-table>
      <hr>
      <refs :refs="refs" />
    </b-card>
  </div>
</template>

<script>
import {
  BBadge, BCard, BCardHeader, BTable, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Refs from '@/views/reports/overview/Refs.vue'
import ExportExcel from '@/views/components/export-excel/ExportExcel.vue'

export default {
  components: {
    BCardHeader,
    BCard,
    BTable,
    BBadge,
    ExportExcel,
    Refs,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    refs: {
      type: Array,
      default: () => [],
    },
    timeFilter: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      rangeDateFilter: '',
      tableColumns: [
        {
          label: 'ĐPC',
          key: 'department_name',
          tdClass: 'text-nowrap text-uppercase text-primary pt-50 pb-50',
          stickyColumn: true,
        },
        {
          label: 'TỔNG HỒ SƠ',
          key: 'contract_count',
        },
        {
          label: 'HS CÔNG TY',
          key: 'company',
        },
        {
          label: 'HS CÁ NHÂN',
          key: 'person',
        },
        {
          label: 'HS ĐNK',
          key: 'offer_to_sign',
        },
        {
          label: 'ĐÃ KÝ',
          key: 'contract_signed',
        },
        {
          label: 'KHÔNG KÝ',
          key: 'contract_denied',
        },
        {
          label: 'HS TRẢ',
          tdClass: 'text-primary',
          key: 'verify_deny',
          formatter: (val, key, item) => item.verify_deny || 0,
        },
        {
          label: 'HS ĐANG XM & CHỜ DUYỆT',
          tdClass: 'text-primary',
          key: 'verify_new',
        },
        {
          label: 'TỔNG HS ĐNK',
          key: 'total_offer_to_sign',
          stickyColumn: true,
        },
        {
          label: 'TỔNG GIÁ TRỊ (VNĐ)',
          key: 'total_sum',
          tdClass: 'font-weight-bolder',
          stickyColumn: true,
          formatter: (val, key, item) => this.formatNumber(item.total_sum || 0),
        },
      ],
    }
  },
  computed: {
    sumRow() {
      return this.tableData[this.tableData.length - 1]
    },
    tableDataFormat() {
      return this.tableData.map(item => ({
        ...item,
        cell2NumVer: {
          contract_count: obj => [
            this.formatNumber(obj.contract_count || 0),
            this.formatNumber(obj.total_sum),
          ],
        },
        // cell2NumHor: undefined,
        cell4Num: {
          company: obj => [
            this.formatNumber(obj.company || 0),
            `~${(100 * (obj.company / this.sumRow.company || 0)).toFixed(0)}%`,
            this.formatNumber(obj.sum_company),
            `~${(100 * (obj.sum_company / (obj.sum_company + obj.sum_personal)) || 0).toFixed(0)}%`,
          ],
          person: obj => [
            this.formatNumber(obj.personal || 0),
            `~${(100 * (obj.personal / this.sumRow.personal || 0)).toFixed(0)}%`,
            this.formatNumber(obj.sum_personal),
            `~${(100 * (obj.sum_personal / (obj.sum_company + obj.sum_personal)) || 0).toFixed(0)}%`,
          ],
        },
      }))
    },
  },
  methods: {
    goContractList(filter, withTimeFilter = true) {
      this.$router.push({
        name: 'contracts-list',
        query: {
          filter: JSON.stringify({
            rangeDateFilter: withTimeFilter ? this.updateRangeFilter() : '',
            ...filter,
          }),
        },
      })
    },
    updateRangeFilter() {
      const q = this.$route.query
      const m = q.monthFilter || ''
      const y = q.yearFilter || ''
      if (!y) {
        this.rangeDateFilter = ' to '
      } else if (!m) {
        this.rangeDateFilter = `${y}-01-01 to ${y}-12-31`
      } else {
        const endDate = new Date(y, m, 0).getDate()
        this.rangeDateFilter = `${y}-${m}-01 to ${y}-${m}-${endDate}`
      }
      return this.rangeDateFilter
    },
    goReportTeamDetail(team) {
      if (!team.department_id) return
      const q = this.$route.query
      const monthFilter = q.monthFilter || ''
      const yearFilter = q.yearFilter || ''
      this.$router.push({
        name: 'reports-team',
        params: { id: team.department_id },
        query: {
          name: team.department_name,
          monthFilter,
          yearFilter,
          t: new Date().getTime(),
        },
      })
    },
  },
}
</script>
