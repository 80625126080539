<template>
  <div>
    <b-card class="custom-border-top-1px">
      <b-card-header class="pl-0 pt-0">
        <b-badge
          variant="light-danger"
          class="font-small-4 px-1 py-50"
        >
          BÁO CÁO HỢP ĐỒNG
        </b-badge>

        <ExportExcel
          :table-title="'BÁO CÁO HỢP ĐỒNG'"
          :table-sub-title="'Thời gian báo cáo: ' + timeFilter"
          :table-data="tableDataFormat"
          :table-columns="tableColumns"
          :table-columns-ex="tableColumnsEx"
        >
          <template #btn-content>
            <feather-icon
              class="text-white"
              icon="FileTextIcon"
            />
            Xuất Excel
          </template>
        </ExportExcel>
      </b-card-header>
      <b-table
        class="position-relative text-center table-col-max-width-100 table-col-min-width-100"
        responsive
        bordered
        small
        striped
        primary-key="id"
        show-empty
        empty-text="Không có kết quả nào"
        :items="tableData"
        :fields="tableColumns"
      >
        <template #thead-top>
          <b-tr>
            <b-th
              colspan="3"
            />
            <b-th
              colspan="6"
              class="text-primary"
            >
              HỢP ĐỒNG PKH ĐÃ KÝ {{ timeFilter }}
            </b-th>
          </b-tr>
        </template>

        <template
          #head(department_name)
          rowspan="2"
        />

        <template
          #head(is_hand_over)
        >
          HĐ ĐỘI ĐÃ NHẬN TRONG {{ timeFilter }}
        </template>

        <template #cell(department_name)="data">
          <span
            class="cursor-pointer"
            @click="goReportTeamDetail(data.item)"
          >{{ data.item.department_name }}</span>
        </template>

        <template #cell(total_all_contract)="data">
          <span
            class="filterable"
            @click="goContractList({isAssignedFilter: 'true', departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', statusFilter: 'contract_signed'}, false)"
          >{{ data.item.total_all_contract || 0 }}</span>
        </template>

        <template #cell(is_hand_over)="data">
          <span
            class="filterable"
            @click="goContractList({typeDateFilter: 'from_hand_over_at', isAssignedFilter: 'true', departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', statusFilter: $status.list.filter(item => item.type === 'hd').map(item => item.value)})"
          >{{ data.item.is_hand_over || 0 }}</span>
        </template>

        <template #cell(contract_count)="data">
          <span
            class="filterable"
            @click="goContractList({isAssignedFilter: 'true', departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', statusFilter: $status.list.filter(item => item.type === 'hd').map(item => item.value)})"
          >{{ data.item.contract_count || 0 }}</span>
        </template>

        <template #cell(company)="data">
          <div
            class="d-flex flex-column align-content-center"
            @click="goContractList({isAssignedFilter: 'true', departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', statusFilter: $status.list.filter(item => item.type === 'hd').map(item => item.value), categoryFilter: 'company'})"
          >
            <div class="d-flex justify-content-between align-items-end">
              <span
                class="filterable font-weight-bolder text-left"
              >{{ data.item.company || 0 }}</span>
              <span class="font-small-3 text-danger text-right">~{{ (100 * (data.item.company / sumRow.company || 0)).toFixed(0) }}%</span>
            </div>
            <div class="d-flex justify-content-between align-items-end">
              <span class="warning text-nowrap font-small-3 text-right">{{ formatNumber(data.item.sum_company) }}</span>
              &nbsp;
              <span class="warning text-nowrap font-small-3 text-right">~{{ (100 * data.item.sum_company / (data.item.sum_company + data.item.sum_personal) || 0).toFixed(0) }}%</span>
            </div>
          </div>
        </template>

        <template #cell(person)="data">
          <div
            class="d-flex flex-column align-content-center"
            @click="goContractList({isAssignedFilter: 'true', departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', statusFilter: $status.list.filter(item => item.type === 'hd').map(item => item.value), categoryFilter: 'personal'})"
          >
            <div class="d-flex justify-content-between align-items-end">
              <span
                class="filterable font-weight-bolder text-left"
              >{{ data.item.personal || 0 }}</span>
              <span class="font-small-3 text-danger text-right">~{{ (100 * (data.item.personal / sumRow.personal || 0)).toFixed(0) }}%</span>
            </div>
            <div class="d-flex justify-content-between align-items-end">
              <span class="warning text-nowrap font-small-3 text-right">{{ formatNumber(data.item.sum_personal) }}</span>
              &nbsp;
              <span class="warning text-nowrap font-small-3 text-right">~{{ (100 * data.item.sum_personal / (data.item.sum_company + data.item.sum_personal) || 0).toFixed(0) }}%</span>
            </div>
          </div>
        </template>

        <template #cell(has_plan)="data">
          <span
            class="filterable"
            @click="goContractList({isAssignedFilter: 'true', departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', hasPlanFilter: 'true', statusFilter: $status.list.filter(item => item.type === 'hd').map(item => item.value)})"
          >{{ data.item.has_plan || 0 }}</span>
        </template>

        <template #cell(no_plan)="data">
          <span
            class="filterable"
            @click="goContractList({isAssignedFilter: 'true', departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', hasPlanFilter: 'false', statusFilter: $status.list.filter(item => item.type === 'hd').map(item => item.value)})"
          >{{ data.item.no_plan || 0 }}</span>
        </template>
      </b-table>
      <hr>
      <refs :refs="refs" />
    </b-card>
  </div>
</template>

<script>
import {
  BBadge, BCard, BCardHeader, BTable, BTh, BTr, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Refs from '@/views/reports/overview/Refs.vue'
import ExportExcel from '@/views/components/export-excel/ExportExcel.vue'

export default {
  components: {
    ExportExcel,
    BCardHeader,
    BCard,
    BTable,
    BBadge,
    BTr,
    BTh,
    Refs,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    refs: {
      type: Array,
      default: () => [],
    },
    timeFilter: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      rangeDateFilter: '',
      tableColumns: [
        {
          label: 'ĐPC',
          key: 'department_name',
          tdClass: 'text-nowrap text-uppercase text-primary pt-50 pb-50',
          thClass: 'text-primary',
          stickyColumn: true,
        },
        {
          label: 'TỔNG HĐ ĐỘI ĐANG THỰC HIỆN',
          key: 'total_all_contract',
        },
        {
          label: `HĐ ĐỘI ĐÃ NHẬN TRONG ${(this.timeFilter.toUpperCase())}`,
          key: 'is_hand_over',
          formatter: (val, key, item) => item.is_hand_over || 0,
        },
        {
          label: 'TỔNG',
          key: 'contract_count',
        },
        {
          label: 'CÔNG TY',
          key: 'company',
        },
        {
          label: 'CÁ NHÂN',
          key: 'person',
        },
        {
          label: 'CÓ KẾ HOẠCH',
          key: 'has_plan',
        },
        {
          label: 'CHƯA CÓ KẾ HOẠCH',
          key: 'no_plan',
        },
        {
          label: 'TỔNG GIÁ TRỊ HĐ (VNĐ)',
          key: 'total_sum',
          stickyColumn: true,
          formatter: (val, key, item) => this.formatNumber(item.total_sum || 0),
        },
      ],
      tableColumnsEx: [
        { isEmpty: true },
        { isEmpty: true },
        { isEmpty: true },
        {
          label: `HỢP ĐỒNG PKH ĐÃ KÝ ${(this.timeFilter.toUpperCase())}`,
          key: 'col_ex_1',
          start: 4,
          end: 9,
        },
        {},
        {},
        {},
        {},
        {},
      ],
    }
  },
  computed: {
    tableDataFormat() {
      return this.tableData.map(item => ({
        ...item,
        // cell2NumVer: undefined,
        // cell2NumHor: undefined,
        cell4Num: {
          company: obj => [
            this.formatNumber(obj.company || 0),
            `~${(100 * (obj.company / this.sumRow.company || 0)).toFixed(0)}%`,
            this.formatNumber(obj.sum_company),
            `~${(100 * (obj.sum_company / (obj.sum_company + obj.sum_personal)) || 0).toFixed(0)}%`,
          ],
          person: obj => [
            this.formatNumber(obj.personal || 0),
            `~${(100 * (obj.personal / this.sumRow.personal || 0)).toFixed(0)}%`,
            this.formatNumber(obj.sum_personal),
            `~${(100 * (obj.sum_personal / (obj.sum_company + obj.sum_personal)) || 0).toFixed(0)}%`,
          ],
        },
      }))
    },
    sumRow() {
      return this.tableData[this.tableData.length - 1]
    },
  },
  methods: {
    goContractList(filter, withTimeFilter = true) {
      this.$router.push({
        name: 'contracts-list',
        query: {
          filter: JSON.stringify({
            rangeDateFilter: withTimeFilter ? this.updateRangeFilter() : '',
            ...filter,
          }),
        },
      })
    },
    updateRangeFilter() {
      const q = this.$route.query
      const m = q.monthFilter || ''
      const y = q.yearFilter || ''
      if (!y) {
        this.rangeDateFilter = ' to '
      } else if (!m) {
        this.rangeDateFilter = `${y}-01-01 to ${y}-12-31`
      } else {
        const endDate = new Date(y, m, 0).getDate()
        this.rangeDateFilter = `${y}-${m}-01 to ${y}-${m}-${endDate}`
      }
      return this.rangeDateFilter
    },
    goReportTeamDetail(team, filter) {
      if (!team.department_id) return
      const q = this.$route.query
      const monthFilter = q.monthFilter || ''
      const yearFilter = q.yearFilter || ''
      this.$router.push({
        name: 'reports-team',
        params: { id: team.department_id },
        query: {
          name: team.department_name,
          monthFilter,
          yearFilter,
          ...filter,
          t: new Date().getTime(),
        },
      })
    },
  },
}
</script>
